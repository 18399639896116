/**
 * @namespace eb.ecc.fe.base.js
 * @module objectHelper
 * @description some often needed json funtcions.
 * @author tl
 * @createDate 21.04.2020
 * @lastUpdateBy tl
 * @lastUpdateDate 11.10.2021
 * @version  1.1 (fileVersion)
 */
import Vue from 'vue';
import DTH from '@/base/js/DateTimeHelper.js';

/**
 * @function setProperty
 * @description sets an deep property in json-object
 * @param {string} prop - the property-path
 * @param {object} obj - the target object
 * @param {any} value - the value to be set
 * @param {boolean} addPath - create path if  not existing
 * @return {boolean} true if value was set, false if "path" not existing
 */
export const setProperty = (prop, obj, value, addPath = true) => {
  prop = prop.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  prop = prop.replace(/^\./, ''); // strip a leading dot
  let a = prop.split('.');
  let p = a.at(-1);
  for (let i = 0; i < a.length - 1; i++) {
    let key = a[i];
    if (key in obj) {
      obj = obj[key];
    } else {
      if (addPath) {
        obj[key] = {};
        obj = obj[key];
      } else {
        return false; // property path not found
      }
    }
  }
  obj[p] = value;
  return true;
};

/**
 * @function getCircularReplacer
 * @description replaces a json-object to prevent circular structures
 * @return {object} The value
 */
export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

/**
 * @function stringify
 * @description stringify a json-object
 *
 * @param {object} json - the object
 * @param {boolean} format - if true json is formatted
 * @return {object[]} The sorted list
 */
export const stringify = (json, format = false) => {
  if (format === true) return JSON.stringify(json, getCircularReplacer(), 2);
  return JSON.stringify(json, getCircularReplacer());
};

/**
 * @function parse
 * @description parses a string to a json-object
 *
 * @param {string} str - the string to be parsed
 * @return {object} The parsed object
 */
export const parse = (str) => {
  return JSON.parse(str);
};

/**
 * @function sortObjects
 * @description sorts array of objects as defined in the orderBy part of payload
 *
 * @param {object[]} items The objects to be sorted
 * @param {object} orderBy The orderBy part as delivered in ioData.restrictions.orderBy
 * @return {object[]} The sorted list
 */
/**
 * orderBy =
 *  [{
 *       "propertyName": {string},  // sortKey
 *       "dir": 'ASC' | 'DESC' // ascending or descending sorting (default = ASC)
 *   }]
 */
export const sortObjects = (items, orderBy) => {
  let ret = []; // the returned item list

  if (items == null) {
    return null;
  } else if (items.length < 1) {
    return ret; // return an empty list
  }

  // validate all orderBy objects and set default to 'ASC'
  if (orderBy == null) {
    throw new ReferenceError('Missing parameter orderBy!');
  }

  for (let orderByObject of orderBy) {
    // check mandatory attributes
    if (orderByObject.propertyName == null) { throw new ReferenceError('Missing orderBy.propertyName!'); }

    if (orderByObject.dir != null) {
      orderByObject.dir = orderByObject.dir.toUpperCase();
      if (orderByObject.dir !== 'ASC' && orderByObject.dir !== 'DESC') { throw new ReferenceError('Wrong value for orderBy.dir! Must be \'asc\' or \'desc\''); }
    } else {
      orderByObject.dir = 'ASC'; // asc is the default sort-order
    }
  }

  // clone items to ret
  items.forEach(item => ret.push(Object.assign({}, item)));

  /* implementation of the compare (arrow)function:
      If the result is negative a is sorted before b.
      If the result is positive b is sorted before a.
      If the result is 0 no changes are done with the sort order of the two values.
  */
  ret.sort((a, b) => {
    const compareDeep = (a, b, orderBy, recursionStep) => {
      let prop = orderBy[recursionStep].propertyName;
      let aValue = a[prop];
      let bValue = b[prop];
      let dir = orderBy[recursionStep].dir;

      if (dir === 'DESC') {
        if (bValue < aValue) {
          return -1;
        } else if (bValue > aValue) {
          return 1;
        } else if (orderBy.length > ++recursionStep) { // condition is equal
          return compareDeep(a, b, orderBy, recursionStep);
        }
        return 0;
      } else { // dir === 'ASC'
        if (aValue < bValue) {
          return -1;
        } else if (aValue > bValue) {
          return 1;
        } else if (orderBy.length > ++recursionStep) { // condition is equal
          return compareDeep(a, b, orderBy, recursionStep);
        }
        return 0;
      }
    }; // end function definition of compareDeep()

    // call it with first recursion step
    return compareDeep(a, b, orderBy, 0);
  });

  return ret;
};

/**
 * @function paginateObjects
 * @description paginates array of objects as defined in the paging part of payload
 *
 * @param {object[]} items The objects to be paginated
 * @param {object} paging The paging part as delivered in ioData.restrictions.paging
 * @return {object[]} The paginated list
 */
/**
 * orderBy =
 *  {
 *    "numberPerPage": {int}, // number of objects per page
 *    "page": {int} // page to be returned (current page)
 *  }
 */
export const paginateObjects = (items, paging) => {
  let ret; // the returned item list

  if (items == null) {
    return null;
  }

  /* validate paging object

  */
  if (paging == null) {
    throw new ReferenceError('Missing parameter paging!');
  } else if (paging.numberPerPage == null || paging.page == null) {
    throw new ReferenceError('Missing attributes in paging! Expected \'numberPerPage\' and \'page\'');
  }

  if (paging.page < 1) {
    throw new RangeError('Paging out of range! The requested page number is too small: ' + paging.page);
  }

  let isArray = Array.isArray(items);
  let completeLength = 0;

  if (isArray) { // items is an array
    completeLength = items.length;
    ret = [];

    if (completeLength < 1) {
      return ret; // return the empty array
    }
    // clone items to ret
    items.forEach(item => ret.push(Object.assign({}, item)));
  } else { // items is a JSON
    completeLength = Object.keys(items).length;

    if (completeLength < 1) {
      return {}; // return an empty JSON
    }

    // clone items to ret
    ret = Object.assign({}, items);
  }

  if (paging.numberPerPage > completeLength) {
    if (paging.page === 1) {
      return ret;
    } else {
      throw new RangeError('Paging out of range! The requested page number is too large: ' + paging.page);
    }
  }

  // the requested page runs out of the limit
  if (paging.numberPerPage * (paging.page - 1) >= completeLength) {
    throw new RangeError('Paging out of range! The requested page number is too large: ' + paging.page);
  }

  let from = paging.numberPerPage * (paging.page - 1);
  let to = paging.numberPerPage * (paging.page);

  if (isArray) {
    ret = ret.slice(from, to); // end element is not included!
  } else {
    if (to > Object.keys(ret).length) {
      to = Object.keys(ret).length;
    }

    let paged = {};
    for (from; from < to; from++) {
      // paged[item] = ret[from];
      console.log(ret[from]);

      let key = Object.keys(ret)[from];
      paged[key] = ret[key];
    }
    ret = paged;
  }

  return ret;
};

/**
 * @function simpleFilter
 * @description filters array of objects by given filter-object
 *
 * @param {object[]} items The objects to be filtered
 * @param {object} filter The paging part as delivered in ioData.restrictions.paging
 * @return {object[]} The filtered list
 */
/**
 * filter =
 *  {
 *    "PROPERTY1": {any},
 *    "PROPERTY2": {any},
 *    ...
 *  }
 */
export const simpleFilter = (items, filter) => {
  let filteredItems = items;
  for (let f of Object.keys(filter)) {
    let fValue = filter[f];
    if (fValue != null && fValue.length > 0) {
      filteredItems = filteredItems.filter((e) => e[f] != null && e[f].toLowerCase().startsWith(fValue.toLowerCase()));
    }
  }
  return filteredItems;
};

export const complexFilter = (items, filterObject) => {
  if (items == null) return null;

  let filteredItems = items;
  // loop over all filters
  for (let propertyName of Object.keys(filterObject)) {
    let filter = filterObject[propertyName];
    if (filter.value != null) {
      if (filter.value === Vue.prototype.$t('base.NULL')) {
        filteredItems = filterForNull(filteredItems, propertyName, filter);
      } else if (filter.condition.startsWith('date.')) {
        filteredItems = filterDate(filteredItems, propertyName, filter);
      } else if (filter.condition.startsWith('arr.')) {
        filteredItems = filterArray(filteredItems, propertyName, filter);
      } else if (filter.condition === 'bool') {
        filteredItems = filteredItems.filter((e) => Boolean(e[propertyName]) === Boolean(filter.value));
      } else {
        filteredItems = filterBase(filteredItems, propertyName, filter);
      }
    }
  }
  return filteredItems;
};

const filterForNull = (filteredItems, propertyName, filter) => {
  if (filter.condition.startsWith('arr.')) {
    filteredItems = filteredItems.filter((e) => e[propertyName] != null && e[propertyName].includes(null));
  } else {
    filteredItems = filteredItems.filter((e) => e[propertyName] == null);
  }
  return filteredItems;
};

const filterDate = (filteredItems, propertyName, filter) => {
  switch (filter.condition.substr(5)) {
    // date-filter
    case '=':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && DTH.isEqual(e[propertyName], filter.value)
      );
      break;
    case '!=':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && DTH.isNotEqual(e[propertyName], filter.value)
      );
      break;
    case '<':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && DTH.isEarlier(e[propertyName], filter.value)
      );
      break;
    case '>':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && DTH.isLater(e[propertyName], filter.value)
      );
      break;
    case '<=':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && DTH.isEarlierOrEqual(e[propertyName], filter.value)
      );
      break;
    case '>=':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && DTH.isLaterOrEqual(e[propertyName], filter.value)
      );
      break;
    case 'between':
      let tempfilter = {
        condition: 'date.>=', value: filter.value
      };
      if (filter.validFrom !== null) {
        tempfilter.value = filter.validFrom;
        filteredItems = filterDate(filteredItems, propertyName, tempfilter);
      } else {
        tempfilter.value = DTH.BASEDATE;
        filteredItems = filterDate(filteredItems, propertyName, tempfilter);
      }
      if (filter.validTo !== null) {
        tempfilter.condition = 'date.<=';
        tempfilter.value = filter.validTo;
        filteredItems = filterDate(filteredItems, propertyName, tempfilter);
      } else {
        tempfilter.condition = 'date.<=';
        tempfilter.value = DTH.INFINITEDATE;
        filteredItems = filterDate(filteredItems, propertyName, tempfilter);
      }
      break;
  }

  return filteredItems;
};

const filterArray = (filteredItems, propertyName, filter) => {
  switch (filter.condition.substr(4)) {
    // string-filter
    case 'like':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].find((v) => v != null && v.toLowerCase().includes(filter.value.toLowerCase())) != null
      );
      break;
    case 'startsWith':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].find((v) => v != null && v.toLowerCase().startsWith(filter.value.toLowerCase())) != null
      );
      break;
    case 'is':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].find((v) => v != null && v.toLowerCase() === filter.value.toLowerCase()) != null
      );
      break;
    case 'isnot':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].find((v) => v != null && v.toLowerCase() !== filter.value.toLowerCase()) != null
      );
      break;
    // number-filter
    case '=':
      console.log('checkfor', Number(filter.value));
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].find((v) => v != null && Number(v) === Number(filter.value)) != null
      );
      break;
    case '!=':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].find((v) => v != null && Number(v) !== Number(filter.value)) != null
      );
      break;
    case '<':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].find((v) => v != null && Number(v) < Number(filter.value)) != null
      );
      break;
    case '>':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].find((v) => v != null && Number(v) > Number(filter.value)) != null
      );
      break;
    case '<=':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].find((v) => v != null && Number(v) <= Number(filter.value)) != null
      );
      break;
    case '>=':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].find((v) => v != null && Number(v) >= Number(filter.value)) != null
      );
      break;
  }
  return filteredItems;
};

const filterBase = (filteredItems, propertyName, filter) => {
  switch (filter.condition) {
    // string-filter
    case 'like':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].toLowerCase().includes(filter.value.toLowerCase())
      );
      break;
    case 'startsWith':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].toLowerCase().startsWith(filter.value.toLowerCase())
      );
      break;
    case 'is':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].toLowerCase() === filter.value.toLowerCase()
      );
      break;
    case 'isnot':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && e[propertyName].toLowerCase() !== filter.value.toLowerCase()
      );
      break;
    // number-filter
    case '=':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && Number(e[propertyName]) === Number(filter.value)
      );
      break;
    case '!=':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && Number(e[propertyName]) !== Number(filter.value)
      );
      break;
    case '<':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && Number(e[propertyName]) < Number(filter.value)
      );
      break;
    case '>':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && Number(e[propertyName]) > Number(filter.value)
      );
      break;
    case '<=':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && Number(e[propertyName]) <= Number(filter.value)
      );
      break;
    case '>=':
      filteredItems = filteredItems.filter(
        (e) => e[propertyName] != null && Number(e[propertyName]) >= Number(filter.value)
      );
      break;
  }
  return filteredItems;
};
